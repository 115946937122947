<template>
    <div class="profile-info">
        <div class="profile-info__title">Профиль</div>
        
        <div
            v-if="profileInfo"
            class="profile-info__content"
        >
            <div
                v-if="profileInfo.email"
                class="profile-info__box"
            >
                <div class="profile-info__text">Email:</div>
                <div
                    v-html="profileInfo.email"
                    class="profile-info__text profile-info__text_value"
                />
            </div>
            
            <div
                v-if="profileInfo.phone"
                class="profile-info__box"
            >
                <div class="profile-info__text">Телефон:</div>
                <div
                    v-html="profileInfo.phone"
                    class="profile-info__text profile-info__text_value"
                />
            </div>
            
            <div
                v-if="userTariff"
                class="profile-info__box"
            >
                <div class="profile-info__text">Тариф:</div>
                <div
                    v-html="userTariff.title ? userTariff.title : '–'"
                    class="profile-info__text profile-info__text_value"
                />
            </div>
        </div>
        <div
            v-if="profileAvailableTariffs && profileInfo && !profileInfo.tariffId"
            class="profile-info__tariffs"
        >
            <div class="profile-info__tariffs-content">
                <CardTariff
                    v-for="tariff in profileAvailableTariffs"
                    v-model="tariffId"
                    :data="tariff"
                    :show-button="true"
                    @buy-click="buyTariff"
                    class="profile-info__tariffs-item"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';

    import CardTariff from '../card-tariff/card-tariff';

    export default {
        components: {
            CardTariff,
        },
        data: () => ({
            tariffId: null,
        }),
        computed: {
            ...mapState({
                profileInfo: (state) => state.profileInfo,
                profileAvailableTariffs: (state) => state.profileAvailableTariffs,
            }),
            userTariff() {
                const result = this.profileAvailableTariffs.find((tariff) => tariff.id === this.profileInfo.tariffId);
                return result ? result : '–';
            },
        },
        methods: {
            async buyTariff (tariff) {
                await this.$store.dispatch('buyTariff', { tariffId: tariff.id });
            },
        },
    }
</script>

<style src="./profile-info.less" lang="less" />