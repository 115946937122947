<template>
    <div class="card-analyze">
        <div
            v-if="!data.isPaid"
            class="card-analyze__demo"
        >Демо</div>
        <div class="card-analyze__field card-analyze__field_date">
            <div class="card-analyze__text card-analyze__text_date">{{ data.date }}</div>
        </div>
        <div class="card-analyze__field">
            <div class="card-analyze__text card-analyze__text_label">Фамилия Имя Отчество</div>
            <div class="card-analyze__text">{{ data.lastName }} {{ data.firstName }} {{ data.middleName }}</div>
        </div>
        <div class="card-analyze__field">
            <div class="card-analyze__text card-analyze__text_label">Дата рождения</div>
            <div class="card-analyze__text">{{ data.birthdate }}</div>
        </div>
        <div v-if="false" class="card-analyze__field">
            <div class="card-analyze__text card-analyze__text_label">Алфавит (язык)</div>
            <div class="card-analyze__language">
                <div class="card-analyze__language-icon">
                    <img
                        :src="getAlphabetIcon(data.alphabet)"
                        class="card-analyze__language-icon-img"
                    >
                </div>
                <div class="card-analyze__text card-analyze__language-text">{{ getAlphabetText(data.alphabet) }}</div>
            </div>
        </div>
        <div class="card-analyze__field card-analyze__field_links">
            <div
                @click="getAnalyzeId(data.id)"
                class="card-analyze__link card-analyze__link_view"
            ><span>Просмотреть</span></div>
            <div
                @click="getPdf(data.id)"
                class="card-analyze__link card-analyze__link_download"
            ><span>Скачать PDF</span></div>
        </div>
    </div>
</template>

<script>
    import alphabets from "../../_data/alphabets.json";

    export default {
        props: {
            data: Object,
        },
        data: () => ({
            alphabets,
        }),
        computed: {

        },
        methods: {
            async getAnalyzeId(id){
                await this.$store.dispatch('getAnalyzeId', id).then((response) => {
                    this.$store.commit('setAnalyze', response.data);
                    this.$router.push('/analyze');
                }).catch((error) => {
                    console.error(error);
                });
            },
            getAlphabetText(id){
                const result = alphabets.find((alphabet) => alphabet.id === id);
                return result.text;
            },
            getAlphabetIcon(id){
                let result = alphabets.find((alphabet) => alphabet.id === id);
                return result.icon;
            },
            async getPdf(id){
                await this.$store.dispatch('getPdf', id).then((response) => {
                    this.downloadFile(new Blob([response]), "myfile.pdf");
                }).catch((error) => {
                    console.error(error);
                });
            },
            downloadFile(blob, fileName){
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                document.body.append(link);
                link.click();
                link.remove();
                setTimeout(() => URL.revokeObjectURL(link.href), 7000);
            },
        },
    }
</script>

<style src="./card-analyze.less" lang="less"></style>