<template>
    <div class="section-card">
        <div
            v-if="data.title"
            class="section-card__title"
        >{{ data.title }}</div>
        <div class="section-card__content">
            <img
                v-if="data.image && data.title"
                :src="data.image"
                :alt="data.title"
                class="section-card__image"
            >
            <div
                v-if="data.text"
                v-html="data.text"
                class="section-card__text"
            ></div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            data: Object,
        },
    }
</script>

<style src="./section-card.less" lang="less"></style>