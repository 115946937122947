<template>
    <div class="page home">
        <div class="inner page__inner home__inner">
            <div class="page__content home__content">
                <div class="home__title">
                    Камни и талисманы<br />
                    по методу Шахрай
                </div>
                <div class="home__text">
                  Метод Шахрай, являющийся первым и единственным в мире подходом своего рода, официально запатентован
                  и признан на государственном уровне как интеллектуальная собственность. Эта инновационная система
                  разработана специально для выявления персональных талисманов каждого индивида, предоставляя уникальные ключи к позитивным изменениям в жизни через определение личных символов удачи и силы.
                  <br /><br>
                  В основе метода лежит аналитика уникальных цифровых последовательностей, ассоциируемых с личностными данными человека, что позволяет изучать статистические связи между этими последовательностями и различными аспектами жизни человека, включая психологические характеристики, поведенческие модели и ключевые жизненные события. Применяя методы математической статистики, метод Шахрай систематически анализирует данные, выявляя потенциальные закономерности, которые помогают в определении индивидуальных талисманов, усиливающих личную силу и способствующих достижению гармонии в жизни.
                  <br><br>
                  Таким образом, Вы точно будете знать какие камни подходят именно Вам: <br>
                  • как талисманы и обереги;
                  <br><br>
                  Какие камни станут для Вас: <br>
                  • персональными помощниками;<br>
                  • защитниками;<br>
                  • хранителями энергии;
                  <br><br>
                  Какие камни притягивают: <br>
                  • финансовые потоки;<br>
                  • положительные события;<br>
                  • энергии любви <br>
                  и приводят Вас к желаемому результату.
                </div>
                <form @submit.prevent="submitAnalyze" class="home__form">
                    <div class="home__form-content">

                        <Field class="home__form-field">
                            <template #label>Фамилия <span>(при рождении)</span></template>
                            <template #content>
                                <InputText v-model="form.lastName" placeholder="Иванов" />
                            </template>
                            <template v-if="$v.form.lastName.$error" #error>{{
                                $v.form.lastName.$dirty ? getError($v.form.lastName) : ''
                            }}</template>
                        </Field>

                        <Field class="home__form-field">
                            <template #label>Имя <span>(при рождении)</span></template>
                            <template #content>
                                <InputText v-model="form.firstName" placeholder="Иван" />
                            </template>
                            <template v-if="$v.form.firstName.$error" #error>{{
                                $v.form.firstName.$dirty ? getError($v.form.firstName) : ''
                            }}</template>
                        </Field>

                        <Field class="home__form-field">
                            <template #label>Отчество <span>(при рождении)</span></template>
                            <template #content>
                                <InputText v-model="form.middleName" placeholder="Иванович" />
                            </template>
                            <template v-if="$v.form.middleName.$error" #error>{{
                                $v.form.middleName.$dirty ? getError($v.form.middleName) : ''
                            }}</template>
                        </Field>

                        <Field class="home__form-field">
                            <template #label>Дата рождения</template>
                            <template #content>
                                <datepicker
                                    v-model="form.birthdate"
                                    placeholder="01.01.1992"
                                    format="dd.MM.yyyy"
                                    :minimumView="'day'"
                                    :maximumView="'year'"
                                    :initialView="'year'"
                                    :language="ru"
                                ></datepicker>
                            </template>
                            <template v-if="$v.form.birthdate.$error" #error>{{
                                $v.form.birthdate.$dirty ? getError($v.form.birthdate) : ''
                            }}</template>
                        </Field>

                        <Field class="home__form-field">
                            <template #label>Email</template>
                            <template #content>
                                <InputText
                                    v-model="form.email"
                                    type="email"
                                    placeholder="email@mail.ru"
                                />
                            </template>
                            <template v-if="$v.form.email.$error" #error>{{
                                $v.form.email.$dirty ? getError($v.form.email) : ''
                            }}</template>
                        </Field>

                        <Field class="home__form-field">
                            <template #label>Телефон</template>
                            <template #content>
                                <InputText
                                    v-model="form.phone"
                                    mask="+7 (###) ###-##-##"
                                    type="tel"
                                    placeholder="+7 (999) 444 55-55"
                                />
                            </template>
                        </Field>
                    </div>

                    <Button submit class="home__form-button">Произвести расчет</Button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import { ru } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import 'moment/locale/ru';
import { required } from 'vuelidate/lib/validators';

import Field from '../../components/field/field';
import InputText from '../../components/input-text/input-text';
import Button from '../../components/button/button';

import alphabets from '../../_data/alphabets.json';

export default {
    data: () => ({
        ru,
        alphabets,
        pending: false,
        form: {
            firstName: null,
            middleName: null,
            lastName: null,
            birthdate: null,
            alphabet: alphabets[0].id,
            email: null,
            phone: null,
        },
    }),
    components: {
        Datepicker,
        Field,
        InputText,
        Button,
    },
    computed: {
        ...mapGetters(['isAuthenticated']),
    },
    validations: {
        form: {
            firstName: {
                required,
            },
            middleName: {
                required,
            },
            lastName: {
                required,
            },
            birthdate: {
                required,
            },
            email: {
                required,
            },
        },
    },
    methods: {
        submitAnalyze() {
            this.$v.$touch();

            if (!this.$v.$invalid && !this.pending) {
                this.pending = true;

                this.$store
                    .dispatch('getAnalyze', {
                        firstName: this.form.firstName,
                        middleName: this.form.middleName,
                        lastName: this.form.lastName,
                        birthdate: moment(this.form.birthdate).format('L'),
                        alphabet: this.form.alphabet,
                        email: this.form.email,
                        phone: this.form.phone,
                    })
                    .then((response) => {
                        this.pending = false;
                        this.$store.commit('setAnalyze', response.data);
                        this.$router.push('/analyze');
                        
                        if (this.isAuthenticated) {
                            this.$store.dispatch('getProfileAnalyzes');
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },
    },
};
</script>

<style src="./home.less" lang="less"></style>
