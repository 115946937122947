import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import router from './router';
import store from './store';

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import vSelect from 'vue-select';
import Vuelidate from 'vuelidate';
import VueTheMask from 'vue-the-mask';
import './mixins/global';

const options = {
    position: 'bottom-right',
    transition: 'fade',
}

Vue.use(VueRouter);
Vue.use(Toast, options);
Vue.component('v-select', vSelect);
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.config.productionTip = false;

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
});

