<template>
    <div class="page analyze">
        <div class="inner page__inner">
            <div class="page__sidebar">
                <div class="page__nav">
                    <SidebarLink
                        v-for="(item, index) in sections"
                        :key="`item-${index}`"
                        :active="activeSection === item.name"
                        :validation="item.validation"
                        @click.native="activeSection = item.name"
                        class="page__nav-item"
                    >{{ item.text }}</SidebarLink>
                </div>
            </div>
            <div class="page__content">
                <transition name="fade" mode="out-in">
                    <div v-if="section" :key="activeSection" class="page__content-holder">
                        <div class="analyze__title">{{ titleSection }}</div>

                        <template
                            v-if="
                                activeSection === 'stones' ||
                                activeSection === 'colors' ||
                                activeSection === 'smells'
                            ">
                            <div class="analyze__stones">
                                <SectionStone
                                    v-for="(stone, index) in section"
                                    :key="`item-${index}`"
                                    :data="stone"
                                    class="analyze__stones-item"
                                />
                            </div>
                        </template>

                        <template v-else-if="activeSection === 'slogan'">
                            <div v-html="section.infoText" class="analyze__text"/>
                            <div v-if="section.cards.length !== 0" class="analyze__slogan">
                                <SectionSlogan
                                    v-for="(card, index) in section.cards"
                                    :data="card"
                                    :key="`card-${index}`"
                                    class="analyze__slogan-item"
                                />
                            </div>
                        </template>

                        <template v-else>
                            <div v-html="section.infoText" class="analyze__text"/>
                            <div v-if="section.cards.length !== 0" class="analyze__cards">
                                <SectionCard
                                    v-for="(card, index) in section.cards"
                                    :data="card"
                                    :key="`card-${index}`"
                                    class="analyze__cards-item"
                                />
                            </div>
                        </template>
                    </div>
                </transition>

                <div v-if="getValidationSection(activeSection)" class="analyze__blocked">
                    <div class="analyze__blocked-content">
                        <div class="analyze__blocked-icon"></div>
                        <div class="analyze__blocked-text">
                            Чтобы получить информацию - купите тариф
                        </div>
                    </div>
                </div>

                <div v-if="getValidationSection(activeSection)" class="analyze__footer">
                    <Button to="/profile" class="analyze__blocked-button">
                        Купить тариф
                    </Button>

                    <Button
                        href="https://forms.amocrm.ru/rlrzmcw"
                        class="analyze__blocked-button"
                    >Заказать индивидуальный браслет
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarLink from '../../components/sidebar-link/sidebar-link';
import SectionCard from '../../components/section-card/section-card';
import SectionStone from '../../components/section-stone/section-stone';
import SectionSlogan from '../../components/section-slogan/section-slogan';
import Button from '../../components/button/button';
import { mapState } from 'vuex';

export default {
    components: {
        SectionSlogan,
        SidebarLink,
        SectionCard,
        SectionStone,
        Button,
    },
    data: () => ({
        activeSection: 'stones',
    }),
    computed: {
        ...mapState({
            analyze: (state) => state.analyze,
        }),
        sections() {
            return [
                {
                    text: 'Талисманы',
                    name: 'stones',
                    validation: this.getValidationSection('stones'),
                },
                {
                    text: 'Характеристика личности',
                    name: 'character',
                    validation: this.getValidationSection('character'),
                },
                {
                    text: 'Сценарий судьбы',
                    name: 'fateScenario',
                    validation: this.getValidationSection('fateScenario'),
                },
                {
                    text: 'Карма',
                    name: 'karma',
                    validation: this.getValidationSection('karma'),
                },
                {
                    text: 'Отработка кармы',
                    name: 'karmaWorkingOut',
                    validation: this.getValidationSection('karmaWorkingOut'),
                },
                {
                    text: 'Задача рода',
                    name: 'genusTasks',
                    validation: this.getValidationSection('genusTasks'),
                },
                {
                    text: 'Девиз жизни',
                    name: 'slogan',
                    validation: this.getValidationSection('slogan'),
                },
                {
                    text: 'Цвета',
                    name: 'colors',
                    validation: this.getValidationSection('colors'),
                },
                {
                    text: 'Запахи',
                    name: 'smells',
                    validation: this.getValidationSection('smells'),
                },
            ];
        },

        titleSection() {
            const result = this.sections.find(
                (section) => section.name === this.activeSection
            );
            return result.text;
        },

        section() {
            return this.analyze[this.activeSection];
        },

        showBlocked() {
            return this.section?.cards?.length === 0 || (!this.section?.lifePeriods && !this.section?.names && !this.section?.soul && !this.section?.personality);
        },
    },
    methods: {
        getValidationSection(name) {
            const item = this.analyze[name];

            if (item.cards && item.cards.length === 0) {
                return true;
            }

            if (
                item?.lifePeriods === null &&
                item?.names === null &&
                item?.personality === null &&
                item?.soul === null
            ) {
                return true;
            }

            return false;
        },
    },
};
</script>

<style src="./analyze.less" lang="less" />
