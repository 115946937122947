<template>
	<div class="card-tariff" @click="redirectToRegistration">
		<div 
			@change="$emit('input', data.id)"
			class="card-tariff__holder"
		>
			<div
				v-html="data.title"
				class="card-tariff__text card-tariff__text_title"
			/>
			<div
				v-html="getAnalyzesQuantity(data.analyzes_quantity)"
				class="card-tariff__text card-tariff__text_description"
			/>
			
			<div
				v-html="`${priceFormatting(data.cost)} ₽`"
				class="card-tariff__text card-tariff__text_price"
			/>
			<div
				v-html="getTextDuration(data.duration)"
				class="card-tariff__text card-tariff__text_info"
			/>
			<Button v-if="showButton"
			@click.native="$emit('buy-click', data)" class="card-tariff__button">
			Купить</Button>
		</div>
	</div>
</template>

<script>
    import Button from '../../components/button/button';

	export default {
		components: {
			Button,
		},
		props: {
		    data: {
		        type: Object,
			    default: null,
		    },
			isDemonstration: {
		        type: Boolean,
				default: false,
			},
			showButton: {
				type: Boolean,
				default: false,
			},
		},
		methods: {
		    getAnalyzesQuantity(quantity) {
		        if (quantity) {
		            return `${quantity} человек`;
		        }
		        
		        return 'без ограничений';
		    },
		    getTextDuration(duration) {
		        if (duration === 12) {
		            return `(${duration / 12} год)`;
		        }
		        
		        if (!duration) {
		            return '(без ограничения по периоду)';
		        }
		        
		        return `(${duration} месяц)`;
			},
			redirectToRegistration () {
				if (this.$route.path === '/tariffs') {
					this.$router.push('/');
				}
			}
		},
	}
</script>

<style src="./card-tariff.less" lang="less" />