import { getInstance } from '../tools';

export function getAnalyze(data) {
    return getInstance().post('/api/analyze', data);
}

export function getAnalyzeId(id) {
    return getInstance().get(`/api/analyze/${id}`);
}

export function exportPdf(id) {
    return getInstance().get(`/api/analyze/export-to-pdf/${id}`, {
        responseType: 'arraybuffer',
    });
}