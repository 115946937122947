<template>
    <div v-if="data" class="section-stone">
        <div
	        v-if="data.title"
	        v-html="data.title"
	        class="section-stone__title"
        />
        <div
	        v-if="data.info"
	        v-html="data.info"
	        class="section-stone__text"
        />
        <div
            v-if="data.items.length !== 0"
            class="section-stone__content"
        >
            <div
                v-for="(item, index) in data.items"
                :key="`item-${index}`"
                class="section-stone__item"
            >
                <div
                    v-if="item.title"
                    v-html="item.title"
                    class="section-stone__item-title"
                />
                <img
                    v-if="item.image"
                    :src="item.image"
                    :alt="item.text"
                    class="section-stone__item-image"
                />
	            <div
		            v-if="item.colors && item.colors.length !== 0"
		            class="section-stone__item-colors"
	            >
					<div
						v-for="color in item.colors"
						class="section-stone__item-color-el"
					>
						<div
							:style="{backgroundColor: color.color}"
							class="section-stone__item-color-el-fill"
						/>
						<div
							v-html="color.title"
							class="section-stone__item-color-el-title"
						/>
					</div>
	            </div>
                <div
                    v-if="item.text"
                    v-html="item.text"
                    class="section-stone__item-text"
                />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            data: {
				type: Object,
	            default: () => {},
            },
        },
    }
</script>

<style src="./section-stone.less" lang="less" />