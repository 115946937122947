<template>
    <div class="page login">
        <div class="inner page__inner">
            <div class="page__content login__content">
                <template v-if="stage === 'login'">
                    <div class="login__title">Вход в личный кабинет</div>
                    <form
                        @submit.prevent="submitLogin"
                        class="login__form"
                    >
                        <div class="login__form-content">
                            <Field class="login__form-field">
                                <template #label>Email</template>
                                <template #content>
                                    <InputText
                                        v-model="form.email"
                                        type="email"
                                        placeholder="email@mail.ru"
                                    />
                                </template>
                                <template v-if="$v.form.email.$error" #error>{{ $v.form.email.$dirty ? getError($v.form.email) : '' }}</template>
                            </Field>

                            <Field class="login__form-field">
                                <template #label>Пароль</template>
                                <template #content>
                                    <InputText
                                        v-model="form.password"
                                        type="password"
                                        placeholder="******"
                                    />
                                </template>
                                <template v-if="$v.form.password.$error" #error>{{ $v.form.password.$dirty ? getError($v.form.password) : '' }}</template>
                            </Field>
                        </div>

                        <Button
                            submit
                            class="login__form-button"
                        >Войти</Button>

                        <div class="login__form-footer">
                            <div class="login__form-recovery" @click="stage = 'recovery'">Забыли пароль?</div>
                        </div>
                    </form>
                </template>
                <template v-else>
                    <div class="login__title">Восстановление пароля</div>
                    <form
                        @submit.prevent="submitRecovery"
                        class="login__form"
                    >
                        <div class="login__form-content">
                            <Field class="login__form-field">
                                <template #label>Email</template>
                                <template #content>
                                    <InputText
                                        v-model="form.email"
                                        type="email"
                                        placeholder="email@mail.ru"
                                    />
                                </template>
                                <template v-if="$v.form.email.$error" #error>{{ $v.form.email.$dirty ? getError($v.form.email) : '' }}</template>
                            </Field>
                        </div>

                        <Button
                            submit
                            class="login__form-button"
                        >Восстановить пароль</Button>
                    </form>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '../../api';

    import Field from "../../components/field/field";
    import InputText from "../../components/input-text/input-text";
    import Button from "../../components/button/button";
    import {required} from "vuelidate/lib/validators";

    export default {
        components: {
            Field,
            InputText,
            Button,
        },
        data: () => ({
            stage: 'login',
            form: {
                email: null,
                password: null,
            },
        }),
        validations() {
            if(this.stage === 'login'){
                return {
                    form: {
                        email: {
                            required,
                        },
                        password: {
                            required,
                        },
                    },
                }
            } else{
                return {
                    form: {
                        email: {
                            required,
                        },
                    },
                }
            }
        },
        methods: {
            submitLogin(){
                this.$v.$touch();

                if(!this.$v.$invalid){
                    api.auth.login(this.form).then((response) => {
                        this.$store.dispatch('login', {token: response.token});
                        this.$store.dispatch('getProfileInfo');
                        this.$store.dispatch('getProfileAnalyzes');

                        this.$nextTick(() => {
                            this.$router.push('/profile');
                        });
                    }).catch((error) => {
                        console.error(error);
                    });
                }
            },
            async submitRecovery(){
                this.form.password = null;

                this.$v.$touch();

                if(!this.$v.$invalid){
                    await this.$store.dispatch('restorePassword', this.form).then((response) => {
                        this.$router.push('/');
                    }).catch((error) => {
                        console.error(error);
                    });
                }
            },
        },
    }
</script>

<style src="./login.less" lang="less"></style>