import axios from 'axios';
import { getToken, setToken } from "../tools/tokenStore";
import router from '@/router';

let instance;

export function getInstance() {
    if (!instance) {
        instance = axios.create({
            baseURL: process.env.VUE_APP_API_URL
        });

        instance.interceptors.request.use(request => {
            const jwtTokenObject = getToken();
            if (jwtTokenObject) {
                request.headers["Authorization"] = `Bearer ${jwtTokenObject.value}`;
                setToken(jwtTokenObject);
            }

            return request;
        });

        instance.interceptors.response.use(
            response => {
                return response.data;
            },
            error => {
                if (error.response.status === 401) {
                    router.replace({ name: 'home' });
                }

                return Promise.reject(error.response);
            }
        );
    }

    return instance;
}
