<template>
    <div class="section-slogan">
        <div
            v-if="data.text"
            v-html="data.text"
            class="section-slogan__title"
        />
    </div>
</template>

<script>
    export default {
        props: {
            data: Object,
        },
    }
</script>

<style src="./section-slogan.less" lang="less" />