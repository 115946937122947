<template>
    <header :class="bem('header', {openedMenu})">
        <div class="inner header__inner">
            <router-link
                to="/"
                class="header__logotype"
            ></router-link>
            <div class="header__menu">
                <router-link
                    v-for="item in menuItems"
                    :to="item.to"
                    @click.native="openedMenu = false"
                    class="header__menu-item"
                >{{ item.text }}</router-link>
            </div>
            <div
                @click="handlerMenu"
                class="header__burger"
            >
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        data: () => ({
            openedMenu: false,
            menuItems: [
                {
                    text: 'Нумерологический анализ',
                    to: '/',
                },
                {
                    text: 'Личный кабинет',
                    to: '/profile',
                },
            ],
        }),
        methods: {
            handlerMenu(){
                this.openedMenu = !this.openedMenu;
            },
        },
    }
</script>

<style src="./header.less" lang="less"></style>