<template>
	<footer class="footer">
		<div class="inner footer__inner">
			<div class="footer__box">
				<div class="footer__text">ИП Шахрай Светлана Михайловна</div>
				<div class="footer__text">ИНН: 263500194811"</div>
				<div class="footer__text">ОГРН: 305263515900181"</div>
			</div>
			<div class="footer__box">
				<a href="tel:+7 (968) 267-52-23" class="footer__text footer__text_link">8-968-267-52-23</a>
				<a href="mailto:keklik.braslets@yandex.ru" class="footer__text footer__text_link">keklik.braslets@yandex.ru</a>
			</div>
			<div class="footer__box">
				<router-link to="/information" class="footer__text footer__text_link">Порядок оказания услуг и возврата</router-link>
				<router-link to="/tariffs" class="footer__text footer__text_link">Тарифы</router-link>
				<a target="_blank" href="/files/public-oferta.pdf" class="footer__text footer__text_link">Пользовательское соглашение</a>
			</div>
		</div>
	</footer>
</template>

<script>
	export default {
	
	}
</script>

<style src="./footer.less" lang="less" />