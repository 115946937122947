import { getInstance } from '../tools';

export function getProfileInfo() {
    return getInstance().get('/api/profile');
}

export function getProfileAnalyzes() {
    return getInstance().get('/api/profile/analyzes')
}

export function getSearchAnalyze(data) {
    return getInstance().get(`/api/profile/analyzes?search=${data}`)
}

export function getAvailableTariffs() {
    return getInstance().get('/api/profile/available-tariffs');
}

export function buyTariff(tariffId) {
    return getInstance().post('/api/profile/buy-tariff', tariffId);
}