<template>
    <div class="input-holder">
        <template v-if="mask">
            <TheMask
                :type="type"
                :mask="mask"
                :placeholder="placeholder"
                @input.native="onInput($event)"
                class="input-text"
            />
        </template>
        <template v-else>
            <input
                :type="type"
                :placeholder="placeholder"
                @input="onInput($event)"
                class="input-text"
            />
        </template>
    </div>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: 'text',
            },
            placeholder: String,
            mask: String,
        },
        methods: {
            onInput(e){
                this.$emit('input', e.target.value)
            },
        },
    }
</script>

<style src="./input-text.less" lang="less"></style>