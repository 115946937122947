export function clone(object) {
    return JSON.parse(JSON.stringify(object));
}

export function camelToKebab(string) {
    return string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
}

export function reachGoal(name) {
    if (window.ym) {
        window.ym(null, 'reachGoal', name);
    }
}

export function getStaticUrl(path, isCssUrl = false) {
    const staticUrl = `${window.STATIC_PATH}/${path}`;
    return isCssUrl ? `url(${staticUrl})` : staticUrl;
}

export function validateEmail(email) {
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
}

export function priceFormatting(data) {
    const price = Number.prototype.toFixed.call(parseFloat(data) || 0, 0);
    let priceSep = price.replace(/(\D)/g, ",");
    priceSep = priceSep.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");

    return priceSep;
}

export function getError(el){
    if(!el.required){
        return 'Обязательное поле';
    }
}