import Vue from 'vue';
import Vuex from 'vuex';

import moment from 'moment';

import api from '../api';
import router from '../router';
import { getToken, setToken, deleteToken } from "../tools/tokenStore";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: getToken()?.value || "",
        analyze: null,
        profileInfo: null,
        profileAnalyzes: null,
        profileAvailableTariffs: [],
    },
    mutations: {
        setAnalyze(state, data){
            state.analyze = data;
        },
        setProfileInfo(state, data){
            state.profileInfo = data;
        },
        setProfileAnalyzes(state, data){
            state.profileAnalyzes = data;
        },
        setAvailableTariffs(state, data) {
            state.profileAvailableTariffs = data;
        },
        login(state, token) {
            state.token = token;
        },
        logout(state) {
            state.token = "";
        },
    },
    actions: {
        async getAnalyze({ commit }, data){
            return await api.analyze.getAnalyze(data);
        },
        async getAnalyzeId({ commit }, id){
            return await api.analyze.getAnalyzeId(id);
        },
        async getPdf({ commit }, id){
            return await api.analyze.exportPdf(id);
        },
        login({ commit }, data){
            setToken({
                value: data.token,
                duration: "long"
            });
            commit("login", data.token);
        },
        logout({ commit }) {
            commit("logout");
            router.push('/');
            deleteToken();
        },
        async restorePassword({ commit }, data){
            return await api.auth.restorePassword(data);
        },
        async getProfileInfo({ commit }){
            await api.profile.getProfileInfo().then((response) => {
                commit('setProfileInfo', response.data);
            }).catch((error) => {
                console.error(error);
            });
        },
        async getProfileAnalyzes({ commit }){
            await api.profile.getProfileAnalyzes().then((response) => {
                commit('setProfileAnalyzes', response.items);
            }).catch((error) => {
                console.error(error);
            });
        },
        async getAvailableTariffs({ commit }) {
            await api.profile.getAvailableTariffs().then((response) => {
                commit('setAvailableTariffs', response);
            }).catch((error) => {
                console.error(error);
            });
        },
        async buyTariff({ commit }, tariffId) {
            await api.profile.buyTariff(tariffId).then((response) => {
                window.location.href = response.paymentUrl;
            }).catch((error) => {
                console.error(error);
            });
        },
        async getSearchAnalyze({ commit }, data) {
            await api.profile.getSearchAnalyze(data).then((response) => {
                commit('setProfileAnalyzes', response.items);
              })
              .catch(error => {
                console.error(error);
              });
          },
    },
    getters: {
        profileInfo(state){
            return state.profileInfo;
        },
        profileAnalyzes(state){
            return state.profileAnalyzes;
        },
        isAuthenticated(state){
            return !!state.token;
        },
        textTariffAnalyzesQuantity(state) {

            if (state.profileInfo && state.profileInfo.tariffRemainedAnalyzesCount > 0) {
                return state.profileInfo.tariffRemainedAnalyzesCount;
            } else {
                return '-';
            }

            return '∞';
        },
        textTariffDuration(state) {
            if (state.profileInfo && !state.profileInfo.tariffId) {
                return '–';
            }

            if (state.profileInfo) {
                if (!state.profileInfo.tariffExpirationDate) {
                    return '∞';
                }

                return `до ${moment(state.profileInfo.tariffExpirationDate).format('LL')}`;
            }

            return null;
        },
    },
});
