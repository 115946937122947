<template>
    <div class="field">
        <div class="field__holder">
            <div class="field__label">
                <slot v-if="$slots.label" name="label"/>
            </div>
            <slot v-if="$slots.content" name="content"/>
        </div>
        <div v-if="$slots.error" class="field__error">
            <slot name="error"/>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style src="./field.less" lang="less"></style>