<template>
    <div class="profile-history">
        <div class="profile-history__title">История анализов</div>
        <input
            class="profile-history__search"
            type="text"
            placeholder="Поиск по Фамилии"
            v-model="searchAnalyze"
            @keydown.enter="getSearchAnalyze(searchAnalyze)"
        />
        <div class="profile-history__content">
            <CardAnalyze
                v-for="(card, index) in profileAnalyzes"
                :key="`card-${index}`"
                :data="card"
                class="profile-history__item"
            />
        </div>
    </div>
</template>

<script>
import {  mapActions, mapGetters } from 'vuex';
import CardAnalyze from '../card-analyze/card-analyze';

export default {
    data() {
        return {
            searchAnalyze: ``,
        };
    },
    components: {
        CardAnalyze,
    },
    computed: {
        ...mapGetters(['profileAnalyzes']),
    },
    methods: {
        ...mapActions(['getSearchAnalyze']),
    },
};
</script>

<style src="./profile-history.less" lang="less"></style>
