import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

import Home from '../views/home/home';
import Analyze from '../views/analyze/analyze';
import Profile from '../views/profile/profile';
import ProfileInfo from '../components/profile-info/profile-info';
import ProfileHistory from '../components/profile-history/profile-history';
import Login from '../views/login/login';
import Information from '../views/information/information';
import Tariffs from '../views/tariffs/tariffs';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/analyze',
        name: 'analyze',
        component: Analyze,
        beforeEnter: (to, from, next) => {
            if(!store.state.analyze){
                next('/');
            }
            else next();
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        beforeEnter: (to, from, next) => {
            if(!store.getters.isAuthenticated){
                next('/login');
            }
            else next();
        },
        children: [
            {
                path: '/',
                component: ProfileInfo,
            },
            {
                path: 'history',
                component: ProfileHistory,
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/information',
        name: 'information',
        component: Information,
    },
    {
        path: '/tariffs',
        name: 'tariffs',
        component: Tariffs,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({ x: 0, y: 0 })
                }, 310);
            });
        }
    },
    routes,
});

export default router;
