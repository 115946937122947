<template>
    <div class="page profile">
        <div class="inner page__inner">
            <div class="page__sidebar">
                <div class="page__nav">
                    <SidebarLink
                        v-for="(link, index) in links"
                        :key="`item-${index}`"
                        :active="$route.fullPath === link.to"
                        :to="link.to"
                        class="page__nav-item"
                    >{{ link.text }}</SidebarLink>
                </div>
                <div class="profile__balance">
                    <div class="profile__balance-title">Баланс</div>
                    <div class="profile__balance-content">
                        <div class="profile__balance-text">Количество анализов: {{ textTariffAnalyzesQuantity }}</div>
                        <div class="profile__balance-text">Срок: {{ textTariffDuration }}</div>
                    </div>
                </div>
                <div
                    @click="logout"
                    class="profile__exit"
                ><span>Выйти</span></div>
            </div>
            <div class="page__content">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import SidebarLink from '../../components/sidebar-link/sidebar-link';

    export default {
        components: {
            SidebarLink,
        },
        data: () => ({
            links: [
                {
                    text: 'Профиль',
                    to: '/profile',
                },
                {
                    text: 'История анализов',
                    to: '/profile/history',
                },
            ],
        }),
        methods: {
            async logout(){
                await this.$store.dispatch('logout');
            },
        },
        computed: {
            ...mapState({
                profileInfo: (state) => state.profileInfo,
            }),
            ...mapGetters([
                'textTariffAnalyzesQuantity',
                'textTariffDuration',
            ]),
        },
        async created() {
            await this.$store.dispatch('getProfileInfo');
        },
    }
</script>

<style src="./profile.less" lang="less" />