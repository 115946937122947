<template>
	<div class="page tariffs">
		<div class="inner tariffs__inner">
			<div class="tariffs__title">Тарифы</div>
			<div
				v-if="profileAvailableTariffs.length !== 0"
				class="tariffs__content"
			>
				<CardTariff
					v-for="tariff in profileAvailableTariffs"
					:data="tariff"
					is-demonstration
					class="tariffs__item"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

    import CardTariff from '../../components/card-tariff/card-tariff';
	
	export default {
	    components: {
            CardTariff,
	    },
		computed: {
		    ...mapState({
                profileAvailableTariffs: 'profileAvailableTariffs',
		    }),
		},
	}
</script>

<style src="./tariffs.less" lang="less" />