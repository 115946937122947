<template>
	<div class="page information">
		<div class="inner content information__inner">
			<h1>Порядок оказания услуг и возврата</h1>
			<h2>Оказание услуг</h2>
			<p>Стоимость услуг по Договору определяется в соответствии с действующими ценами и прописана на сайте <a href="https://keklik.ru/profile">https://keklik.ru/profile</a></p>
			<p>Стоимость услуги может быть изменена Исполнителем в одностороннем порядке.</p>
			<p>Способы оплаты услуги указаны при оформлении платежа.</p>
			<h2>Возврат товара</h2>
			<p>Способы возврата рассматриваются в индивидуальном порядке.</p>
		</div>
	</div>
</template>

<script>
	export default {
	
	}
</script>

<style src="./information.less" lang="less" />
