<template>
    <component
        v-bind="bind"
        :class="bem('button', {background, color, radius})"
    >
        <slot/>
    </component>
</template>

<script>
    export default {
        props: {
            href: String,
            to: String,
            submit: Boolean,
            background: {
                type: String,
                default: 'pink',
            },
            color: {
                type: String,
                default: 'white',
            },
            radius: {
                type: Boolean,
                default: true,
            },
        },
        computed: {
            bind(){
                if(this.href){
                    return {
                        is: 'a',
                        target: '_blank',
                        href: this.href,
                    }
                } else if(this.to){
                    return {
                        is: 'router-link',
                        to: this.to,
                    }
                } else if(this.submit){
                    return {
                        is: 'button',
                    }
                } else{
                    return {
                        is: 'div',
                    }
                }
            }
        },
    }
</script>

<style src="./button.less" lang="less"></style>